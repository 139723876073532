















import { Component, Vue } from 'vue-property-decorator';

import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import PageHeader from '@/components/PageHeader/PageHeader.vue';
import Footer from '@/components/Footer/Footer.vue';

import PlanBanner from './components/PlanBanner/index.vue';
import Descriptions from '../../components/Descriptions/index.vue';
import Marketing from './components/Marketing/index.vue';

@Component({
  components: {
    PageHeader,
    ContainerFluid,
    PlanBanner,
    Descriptions,
    Marketing,
    Footer
  }
})
export default class Descriptive extends Vue {
  private planId = Number(this.$route.params.productId);

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get featureName() {
    const { feature } = this.$route.params;
    return feature ? feature.charAt(0).toUpperCase() + feature.slice(1) : '';
  }

  setPlanId(planId: number) {
    this.planId = planId;
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Loja', to: '/loja' },
      { title: this.featureName, to: null }
    ]);
  }
}
